// the buildigo image API will use `accept` headers to determine the best image format
// for a given browser, therefore there's no need to explicitly request for avif or webp
export function transformBuildigoImageUrl(
  imageSrc: string,
  args?: {width?: number; height?: number; format?: 'avif' | 'webp'},
): string {
  const [url, search] = imageSrc.split('?')
  const searchParams = new URLSearchParams(search)
  if (args?.width) {
    searchParams.set('w', args.width.toFixed())
  }
  if (args?.height) {
    searchParams.set('h', args.height.toFixed())
  }
  if (args?.format) {
    searchParams.set('fmt', args.format)
  }

  // node 18 doesn't support UrlSearchParams.size
  const encodedSearchParams = searchParams.toString()
  if (encodedSearchParams) {
    // we don't use the URL api here because we sometimes want to transform relative URLs
    return url + '?' + encodedSearchParams
  } else {
    return imageSrc
  }
}
